import clsx from 'clsx'
import Image from 'next/image'

export const ModalBottomImageWithVersion = ({
  classNames,
}: {
  classNames?: string
}) => {
  return (
    <div className={clsx('relative h-full max-h-[296px]', classNames)}>
      <Image
        alt=""
        src="/images/curve-illustration.svg"
        className="rounded-b object-cover sm:rounded-b-xl"
        layout="fill"
      />
    </div>
  )
}
