import useInternetConnection from 'hooks/useInternetConnection'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { Socials } from '../config/socials'
import styles from '../styles/Footer.module.css'
import Tooltip from './shared/Tooltip'

const TokenTicker = dynamic(() => import('./TokenTicker'))

const Footer = () => {
  const isOnline = useInternetConnection()

  return (
    <footer className="fixed bottom-0 left-0 z-30 hidden w-full bg-th-bkg-5 md:block">
      <div className="flex w-full justify-between gap-4 border-t border-th-input-border-secondary px-4 font-flexo text-xs font-bold text-th-fgd-2">
        <div className="flex items-center py-1.5">
          <Tooltip content="Connection Status" placement="top">
            <div className="flex items-center gap-1.5 border-r border-th-input-border pr-2">
              {isOnline ? (
                <div className="flex h-3 w-3 items-center justify-center rounded-full bg-th-success-secondary">
                  <div className="h-[7px] w-[7px] rounded-full bg-th-success" />
                </div>
              ) : (
                <div className="flex h-3 w-3 items-center justify-center rounded-full bg-th-error">
                  <div className="h-[7px] w-[7px] rounded-full bg-th-error-secondary" />
                </div>
              )}
              <span className="text-xs font-semibold text-th-fgd-2">
                {isOnline ? 'Connected' : 'Disconnected'}
              </span>
            </div>
          </Tooltip>
        </div>

        <div className="flex-1 overflow-hidden">
          <div className="relative overflow-hidden">
            <div className="absolute left-0 z-20 h-full w-[82px] [background:_linear-gradient(90deg,_rgba(6,_6,_6,_0.30)_0%,_rgba(6,_6,_6,_0.80)_0.01%,_rgba(0,_0,_0,_0.00)_117.31%);]" />
            <div className="absolute right-0 z-20 h-full w-[82px] rotate-180 [background:_linear-gradient(90deg,_rgba(6,_6,_6,_0.30)_0%,_rgba(6,_6,_6,_0.80)_0.01%,_rgba(0,_0,_0,_0.00)_117.31%);]" />
            <TokenTicker />
          </div>
        </div>

        <div className="flex items-center py-1.5">
          <div className="flex items-center justify-center gap-3 border-l border-th-input-border pl-2">
            {Socials.map((item, index) => (
              <a key={index} href={item.url} rel="noreferrer" target="_blank">
                {!item?.hoverIcon ? (
                  <Tooltip content={item.title} placement="top">
                    <div className={styles[item.title.toLowerCase()]}>
                      <item.icon
                        className="cursor-pointer transition-all"
                        height={20}
                        width={20}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <HoveredStateIcon
                    content={item.title}
                    hoverIcon={item.hoverIcon}
                    icon={item.icon}
                  />
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

const HoveredStateIcon = (props: {
  hoverIcon: React.FC<React.SVGProps<SVGSVGElement>>
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  content: string
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Tooltip content={props.content} placement="top">
      <div
        className="w-[21px]"
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? (
          <props.hoverIcon className="cursor-pointer transition-all" />
        ) : (
          <props.icon className="cursor-pointer transition-all" />
        )}
      </div>
    </Tooltip>
  )
}

export default Footer
