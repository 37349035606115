import Modal from '@components/shared/Modal'
import React, { useState } from 'react'
import { ModalBottomImageWithVersion } from './ModalBottomImage'
import { useViewport } from 'hooks/useViewport'
import SuccessIcon from 'public/icons/toast/success.svg'
import Button from './shared/Button'
import useWallet from 'gmx/lib/wallets/useWallet'
import { useEthersSigner } from 'hooks/contracts/useEthersSigner'
import { TNC_FIREBASE_PATH, firestoreDB } from 'config/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { IS_VERBOSE } from 'config/development'

const TermsConditionsModal = () => {
  const { isMobile } = useViewport()
  const { account: address } = useWallet()
  const signer = useEthersSigner()
  const [isOpen, setIsOpen] = useState(true)

  const requestSignature = async () => {
    if (!signer) {
      return
    }

    try {
      const message = 'Sign this message to authenticate'
      const signature = await signer.signMessage(message)
      try {
        address &&
          setDoc(doc(firestoreDB, TNC_FIREBASE_PATH, address), {
            signature,
          })
        setIsOpen(false)
      } catch (e) {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log(e)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error('Error signing message:', error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Terms and Conditions"
      hasImageAtBottom={true}
      panelClassNames={isMobile ? '!h-[500px]' : ''}
      hideClose={true}
      hasSpaceBtwCloseAndTitle={false}
    >
      <div className="flex h-full flex-col justify-between pt-4">
        <div className="inline-flex h-[257px] w-full flex-col items-start justify-start gap-4 px-6">
          <div className="inline-flex items-start justify-start gap-1 self-stretch py-1">
            <div className="h-4 w-5">
              <SuccessIcon className="success-icon" />
            </div>
            <div className="font-['TT Commons Pro'] text-xs font-normal leading-[15px] text-[#909095]">
              I’m not a Person or Entity Who Resides in, is a Citizen of, or is
              Incorporated in, or has a Registered Office in the United States
              or any Prohibited Regions, as Defined in the Terms of Use
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-1 self-stretch py-1">
            <div className="h-4 w-5">
              <SuccessIcon className="success-icon" />
            </div>
            <div className="font-['TT Commons Pro'] text-xs font-normal leading-[15px] text-[#909095]">
              I will not, Now or in the Future, Access this site or use RFX
              while located within the United States or any Prohibited Regions,
              as defined in the Terms of Use.
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-1 self-stretch py-1">
            <div className="h-4 w-5">
              <SuccessIcon className="success-icon" />
            </div>
            <div className="font-['TT Commons Pro'] w-[492px] text-xs font-normal leading-[15px] text-[#909095]">
              I am not Using, and will not in the Future, use, a VPN to mask my
              Physical Location from a Restricted Territory.
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-1 self-stretch py-1">
            <div className="h-4 w-5">
              <SuccessIcon className="success-icon" />
            </div>
            <div className="font-['TT Commons Pro'] w-[492px] text-xs font-normal leading-[15px] text-[#909095]">
              I am Lawfully Permitted to Access this site and use RFX Under the
              Laws of the Jurisdiction in which I Reside and am Located.
            </div>
          </div>
          <div className="inline-flex items-center justify-start gap-1 self-stretch py-1">
            <div className="h-4 w-5">
              <SuccessIcon className="success-icon" />
            </div>
            <div className="font-['TT Commons Pro'] w-[492px] text-xs font-normal leading-[15px] text-[#909095]">
              I understand the Risks Associated with using RFX.
            </div>
          </div>
        </div>
        <ModalBottomImageWithVersion
          classNames={isMobile ? '!max-h-[150px]' : ''}
        />
        <div className="w-full px-4 py-6">
          <Button
            className="font-['TT Commons Pro'] w-full !bg-th-active text-sm font-bold leading-[18px] !text-white"
            onClick={() => requestSignature()}
          >
            SIGN IN AND PROCEED
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TermsConditionsModal
