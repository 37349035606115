import Discord from 'public/icons/footer-discord.svg'
import Github from 'public/icons/footer-github.svg'
import Telegram from 'public/icons/footer-telegram.svg'
import Twitter from 'public/icons/footer-twitter.svg'
import MirrorActive from 'public/icons/mirror-colored.svg'
import Mirror from 'public/icons/mirror.svg'
import { FC, SVGProps } from 'react'

export type SocialIconType = {
  title: string
  icon: FC<SVGProps<SVGSVGElement>>
  iconURL: string
  url: string
  color?: string
  hoverIcon?: FC<SVGProps<SVGSVGElement>>
}

export const Socials: SocialIconType[] = [
  {
    title: 'Discord',
    icon: Discord,
    iconURL: '/icons/footer-discord.svg',
    url: 'https://discord.gg/fKx73YyGVg',
    color: '#5865F2',
  },
  {
    title: 'Twitter',
    icon: Twitter,
    iconURL: '/icons/footer-twitter.svg',
    url: 'https://x.com/RFX_exchange',
    color: '#1DA1F2',
  },
  {
    title: 'Telegram',
    icon: Telegram,
    iconURL: '/icons/footer-telegram.svg',
    url: 'https://t.me/rfx_exchange',
    color: '#0088CC',
  },
  {
    title: 'Github',
    icon: Github,
    iconURL: '/icons/footer-github.svg',
    url: 'https://github.com/relativefi',
    color: '#B8B9BF',
  },
  {
    title: 'Mirror',
    icon: Mirror,
    iconURL: '/icons/mirror.svg',
    url: 'https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383',
    color: '#097AFE',
    hoverIcon: MirrorActive,
  },
]

export const socialIcons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  twitter: Twitter,
  telegram: Telegram,
  discord: Discord,
  mirror: Mirror,
}
