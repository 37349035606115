import { useEffect, useState } from 'react'

const useInternetConnection = () => {
  const [isOnline, setIsOnline] = useState(false)

  useEffect(() => {
    function handleOnline() {
      return setIsOnline(true)
    }

    function handleOffline() {
      return setIsOnline(false)
    }

    if (typeof window !== 'undefined') {
      setIsOnline(navigator.onLine)
      window.addEventListener('online', handleOnline)
      window.addEventListener('offline', handleOffline)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('online', handleOnline)
        window.removeEventListener('offline', handleOffline)
      }
    }
  }, [])

  return isOnline
}

export default useInternetConnection
