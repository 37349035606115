import { Transition } from '@headlessui/react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { IS_VERBOSE } from 'config/development'
import {
  BUTTER_BAR_FIREBASE_PATH,
  TNC_FIREBASE_PATH,
  firestoreDB,
} from 'config/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { useViewport } from 'hooks/useViewport'
import { useTheme } from 'next-themes'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import { cn } from 'utils/classnames'
import { nftThemeMeta } from 'utils/theme'
import { useAccount } from 'wagmi'
import useLocalStorageState from '../hooks/useLocalStorageState'
import { SECONDS, SIDEBAR_COLLAPSE_KEY } from '../utils/constants'
import ButterBar from './ButterBar'
import Footer from './Footer'
import useInterval from './shared/useInterval'
import TermsConditionsModal from './TermsAndConditions'

const SideNav = dynamic(() => import('./SideNavbar/SideNav'))
const TopBar = dynamic(() => import('./TopBar/TopBar'))
const GlobalSearchModal = dynamic(() => import('./GlobalSearchModal'))

export const sideBarAnimationDuration = 300

const Layout = ({ children }: { children: ReactNode }) => {
  const { isMobile } = useViewport()
  const themeData = nftThemeMeta.default
  const { theme } = useTheme()
  const [isCollapsed] = useLocalStorageState(SIDEBAR_COLLAPSE_KEY, false)
  const [showButterBar, setShowButterBar] = useState(false)
  const { address } = useAccount()
  const [showMoreOptions, setshowMoreOptions] = useState(false)
  const [showTnCModal, setShowTnCModal] = useState(false)
  const { asPath } = useRouter()

  useEffect(() => {
    const animationFrames = 15

    for (let x = 1; x <= animationFrames; x++) {
      setTimeout(
        () => {
          window.dispatchEvent(new Event('resize'))
        },
        (sideBarAnimationDuration / animationFrames) * x,
      )
    }
  }, [isCollapsed])

  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  useIsomorphicLayoutEffect(() => {
    if (!address) {
      setShowButterBar(false)
      return
    }

    const docRef = doc(firestoreDB, BUTTER_BAR_FIREBASE_PATH, address)
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          setShowButterBar(docSnap.data().showButterBar)
          return
        }

        setShowButterBar(true)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log(e)
      })

    const docRefSignature = doc(firestoreDB, TNC_FIREBASE_PATH, address)
    getDoc(docRefSignature)
      .then((docSnap) => {
        if (docSnap.exists()) {
          setShowTnCModal(!docSnap.data().signature)
          return
        }

        setShowTnCModal(true)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log(e)
      })
  }, [address])

  const handleButterBarClose = () => {
    setShowButterBar(false)
    try {
      address &&
        setDoc(doc(firestoreDB, BUTTER_BAR_FIREBASE_PATH, address), {
          showButterBar: false,
        })
    } catch (e) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log(e)
    }
  }

  const hasToApplyNoiseClass = useMemo(() => {
    return theme !== 'Stargazer'
  }, [theme])

  if (!mounted) {
    return null
  }

  if (isMobile) {
    return (
      <main
        className={clsx(
          'relative',
          themeData.fonts.body.variable,
          themeData.fonts.flexo.variable,
          hasToApplyNoiseClass && 'noise',
        )}
      >
        <div
          className={cn(
            'z-50 min-h-screen grow text-th-fgd-2 transition-all',
            hasToApplyNoiseClass && 'noise',
          )}
        >
          {!showMoreOptions && <TopBar />}

          <div className="h-full w-full pt-14">
            {showButterBar && (
              <ButterBar
                text={
                  <p className="text-xs font-medium text-th-fgd-2">
                    Read more about the Alpha Testnet rewards and eligibility
                    criteria{' '}
                    <a
                      href="https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383/w6xvsbs5KqGnOtbYSER9ipk2JhdYzzxEIQT45Uk2WaQ"
                      target="_blank"
                      className="text-th-fgd-1"
                    >
                      here
                    </a>
                  </p>
                }
                onClose={() => handleButterBarClose()}
              />
            )}
            {children}
          </div>
          <DeployRefreshManager />
          <GlobalSearchModal />

          <div className="fixed bottom-0 z-50 w-full border-b border-t border-th-input-border bg-th-bkg-1">
            <SideNav
              setshowMoreOptions={setshowMoreOptions}
              showMoreOptions={showMoreOptions}
            />
          </div>
        </div>
      </main>
    )
  }

  return (
    <main
      className={clsx(
        'relative',
        themeData.fonts.body.variable,
        themeData.fonts.flexo.variable,
        hasToApplyNoiseClass && 'noise',
      )}
    >
      <div
        className={cn(
          'z-50 min-h-screen grow text-th-fgd-2 transition-all',
          hasToApplyNoiseClass && 'noise',
        )}
      >
        <TopBar />

        {asPath !== '/market-stats' && (
          <div className="fixed z-10 h-screen pt-16">
            <div className="hide-scroll h-full overflow-y-auto">
              <SideNav
                setshowMoreOptions={setshowMoreOptions}
                showMoreOptions={showMoreOptions}
              />
            </div>
          </div>
        )}

        <div
          className={`h-full w-full transition-all duration-${sideBarAnimationDuration} pt-16 ease-in-out ${
            asPath !== '/market-stats' && (isCollapsed ? 'pl-16' : 'pl-[200px]')
          }`}
        >
          {showButterBar && (
            <ButterBar
              text={
                <p className="text-sm font-medium text-th-fgd-2">
                  Read more about the Alpha Testnet rewards and eligibility
                  criteria{' '}
                  <a
                    href="https://mirror.xyz/0x12Daf0085914D71fEA20Ccf66cb4704b67498383/w6xvsbs5KqGnOtbYSER9ipk2JhdYzzxEIQT45Uk2WaQ"
                    target="_blank"
                    className="text-th-fgd-1"
                  >
                    here
                  </a>
                </p>
              }
              onClose={() => handleButterBarClose()}
            />
          )}
          {children}
        </div>

        <DeployRefreshManager />
        <GlobalSearchModal />
        <Footer />
        {showTnCModal && <TermsConditionsModal />}
      </div>
    </main>
  )
}

function DeployRefreshManager(): JSX.Element | null {
  const [newBuildAvailable, setNewBuildAvailable] = useState(false)

  useInterval(async () => {
    try {
      const response = await fetch('/api/build-id')
      const { buildId } = await response.json()

      if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
        // There's a new version deployed that we need to load
        setNewBuildAvailable(true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log(e)
    }
  }, 300 * SECONDS)

  return (
    <Transition
      appear={true}
      show={newBuildAvailable}
      as={Fragment}
      enter="transition ease-in duration-300"
      enterFrom="translate-y-0"
      enterTo="-translate-y-[130px] md:-translate-y-20"
      leave="transition ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button
        className="fixed -bottom-[46px] left-1/2 z-50 flex -translate-x-1/2 items-center rounded-full border border-th-bkg-4 bg-th-bkg-3 px-4 py-3 shadow-md focus:outline-none md:hover:bg-th-bkg-4 md:hover:shadow-none"
        onClick={() => window.location.reload()}
      >
        <p className="mr-2 whitespace-nowrap text-th-fgd-1">{'New Version'}</p>
        <ArrowPathIcon className="h-5 w-5" />
      </button>
    </Transition>
  )
}
export default Layout
