import Closebutton from 'public/icons/modal-close.svg'
import { ReactNode } from 'react'

interface ButterBarProps {
  text: string | ReactNode
  onClose: () => void
}

const ButterBar = ({ text, onClose }: ButterBarProps) => {
  return (
    <div className="relative flex h-10 w-full items-center bg-th-active text-sm font-medium text-th-fgd-2 max-sm:px-4 sm:h-8 sm:justify-center">
      {text}
      <div
        className="cursor-pointer sm:absolute sm:right-6 sm:top-1/2 sm:-translate-y-1/2 [&_path]:fill-th-fgd-1"
        onClick={onClose}
      >
        <Closebutton />
      </div>
    </div>
  )
}

export default ButterBar
